@font-face {
  font-family: 'PPEiko';
  src: url('./fonts/PPEiko-Black.woff2') format('woff2'),
       url('./fonts/PPEiko-Black.woff') format('woff'),
       url('./fonts/PPEiko-Black.ttf') format('truetype');
       font-weight: 900;
       font-display: swap;
       font-style: normal;
}

@font-face {
  font-family: 'Switzer';
  src: url('./fonts/Switzer-Light.woff2') format('woff2'),
       url('./fonts/Switzer-Light.woff') format('woff');
       font-weight: 500;
       font-display: swap;
       font-style: normal;
}

@font-face {
  font-family: 'Switzer';
  src: url('./fonts/Switzer-Bold.woff2') format('woff2'),
       url('./fonts/Switzer-Bold.woff') format('woff');
       font-weight: 600;
       font-display: swap;
       font-style: normal;
}