@font-face {
  font-family: PPEiko;
  src: url("PPEiko-Black.09cc0008.woff2") format("woff2"), url("PPEiko-Black.2b36fd0d.woff") format("woff"), url("PPEiko-Black.5e517c1d.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Switzer;
  src: url("Switzer-Light.5a7b87c5.woff2") format("woff2"), url("Switzer-Light.af857a6e.woff") format("woff");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Switzer;
  src: url("Switzer-Bold.a9c49bce.woff2") format("woff2"), url("Switzer-Bold.26425472.woff") format("woff");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

/*# sourceMappingURL=index.007be792.css.map */
